import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import {Typography} from '../../base'
import Image from '../../base/Image/Image'


const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledImage = styled(Image)`
  margin-top: -4rem;
  width: 43rem;
  height: 100%;
  max-height: 100%;

  ${media.down('mobile')(css`
    margin-top: -1.25rem;
    width: 15rem;
  `)}
`

const StyledTitle = styled(Typography)`
  text-align: center;
  font-size: 18.75rem;

  ${media.down('mobile')(css`
    font-size: 6rem;
  `)}
`

const Portfolio = ({portfolio}) => (
  <>
    <StyledTitle type="h2" isHeading>{portfolio.title}</StyledTitle>
    <StyledImageContainer>
      <StyledImageContainer>
        <StyledImage url={portfolio.link} alt={portfolio?.alt} />
      </StyledImageContainer>
    </StyledImageContainer>
  </>
)

Portfolio.propTypes = {
  portfolio: PropTypes.object,
}

export default Portfolio
