
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import Image from '../../base/Image/Image'


const StyledImage = styled(Image)`
  display: block;
  width: 100%;
  height: 95vh;
  object-fit: cover;

  ${media.down('mobile')(css`
    height: 70vh;
  `)}
`

const HomepageImage = ({srcSet, alt, ...props}) => (
  <StyledImage url={srcSet} alt={alt} {...props} />
)

HomepageImage.propTypes = {
  srcSet: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default HomepageImage
