import PropTypes from 'prop-types'
import {isEmpty, map} from 'lodash-es'
import {useState} from 'react'
import HomepageImage from '../HomepageImage/HomepageImage'
import Slider from '../Slider/lazy'
import SSRSuspense from '../SSRSuspense/SSRSuspense'


const HomepageSlider = ({featuredImage, banners}) => {
  const [control, setContrtol] = useState(null)

  const fallbackHomepageImage = (
    <HomepageImage srcSet={featuredImage?.node.srcSet} alt={featuredImage?.node.altText} />
  )

  const allCustomBanners = map(banners, (banner) => (
    {
      srcSet: banner?.bannerImage.srcSet,
      alt: banner?.bannerImage.altText,
    }
  ))

  const hasCustomBanners = !isEmpty(allCustomBanners)

  return (
    <SSRSuspense
        fallback={fallbackHomepageImage}
    >
      <Slider
          virtual={false}
          control={control}
          onChange={setContrtol}
          isNavigationActive={hasCustomBanners}
      >
        {fallbackHomepageImage}
        <If condition={hasCustomBanners}>
          <For each="heroImage" of={allCustomBanners} index="idx">
            <HomepageImage key={idx} loading="lazy" srcSet={heroImage.srcSet} alt={heroImage.alt} />
          </For>
        </If>
      </Slider>
    </SSRSuspense>
  )
}

HomepageSlider.propTypes = {
  featuredImage: PropTypes.object,
  banners: PropTypes.arrayOf(PropTypes.object),
}

export default HomepageSlider
