import {useState} from 'react'
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash-es'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import Button from '../Button/Button'
import Slider from '../Slider/lazy'
import SSRSuspense from '../SSRSuspense/SSRSuspense'
import Portfolio from './Portfolio'


const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.down('mobile')(css`
    margin: 2.75rem 0 2rem;
  `)}
`

const StyledButton = styled(Button)`
  background-color: ${({theme}) => theme.colors.background};
`

const StyledLink = styled(Button)`
  display: flex;
  margin-left: 2rem;

  svg {
    margin: 0 0.5rem 0 -0.5rem;
  }

  path,
  rect {
    fill: ${({theme}) => theme.colors.secondary};
  }
`

const SectionPortfolio = ({portfolioImages, showCatalog, portfolioButton}) => {
  const [control, setControl] = useState(null)

  return (
    <SSRSuspense fallback={<Portfolio portfolio={portfolioImages[0]} />}>
      <Slider mode="dark" arrowMobilePosition="down" control={control} onChange={setControl}>
        <For each="sliderImage" of={portfolioImages} index="idx">
          <Portfolio key={idx} portfolio={sliderImage} />
        </For>
      </Slider>
      <StyledButtonContainer>
        <If condition={!isEmpty(showCatalog)}>
          <StyledButton to={showCatalog.url}>
            {showCatalog.title}
          </StyledButton>
        </If>
        <If condition={!isEmpty(portfolioButton)}>
          <StyledLink type="text" to={portfolioButton?.url}>
            {portfolioButton?.title}
          </StyledLink>
        </If>
      </StyledButtonContainer>
    </SSRSuspense>
  )
}

SectionPortfolio.propTypes = {
  showCatalog: PropTypes.object,
  portfolioButton: PropTypes.object,
  portfolioImages: PropTypes.array,
}

export default SectionPortfolio
