import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {isEmpty, map} from 'lodash-es'
import styled from 'styled-components'
import {AppShell, Box, Button, CounterList, Experience, SectionMaterials} from '../components/custom'
import {Typography} from '../components/base'
import ContentContainer from '../components/base/ContentContainer/ContentContainer'
import HomepageSlider from '../components/custom/HomepageSlider/HomepageSlider'
import SectionPortfolio from '../components/custom/SectionPortfolio/SectionPortfolio'
import WpContent, {DefaultWrapper} from '../components/base/WpContent/lazy'
import SSRSuspense from '../components/custom/SSRSuspense/SSRSuspense'


const StyledLink = styled(Button)`
  display: flex;
  margin-left: 0.5rem;

  svg {
    margin: 0 0.5rem 0 -0.5rem;
  }

  path,
  rect {
    fill: ${({theme}) => theme.colors.secondary};
  }
`

const StyledButton = styled(Button)`
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

const Index = ({data, pageContext: {lang}}) => {
  const {title, featuredImage, content, seo} = data.wpPage
  const {materials, portfolios, ourStory, experience, allMaterialsButton, showCatalog, portfolioButton, counters,
    banners, knowUsText} = data.wpPage?.template?.homePage
  const fallBack = (<DefaultWrapper>{content}</DefaultWrapper>)

  const portfolioImages = map(portfolios, (portfolio) => (
    {
      title: portfolio.portfolio?.title,
      link: portfolio.portfolio?.featuredImage.node?.srcSet,
      alt: portfolio.portfolio?.featuredImage.node?.altText,
    }
  ))

  return (
    <AppShell
        title={title}
        lang={lang}
        seo={seo}
        langToSlug={{sk: '/', en: '/en', pl: '/pl', de: '/de', hu: '/hu'}}
    >
      <HomepageSlider featuredImage={featuredImage} banners={banners} />
      <Typography type="h1" srOnly>{title}</Typography>
      <If condition={!isEmpty(knowUsText)}>
        <StyledButton to="#nas-pribeh">
          {knowUsText}
        </StyledButton>
      </If>
      <If condition={Boolean(content)}>
        <ContentContainer>
          <SSRSuspense fallback={fallBack}>
            <WpContent>
              {content}
            </WpContent>
          </SSRSuspense>
        </ContentContainer>
      </If>
      <If condition={!isEmpty(ourStory)}>
        <ContentContainer id="nas-pribeh">
          <Box
              title={ourStory?.title}
              description={ourStory?.description}
              image={ourStory?.photo}
              footer={
                <StyledLink type="text" to={ourStory?.wholeStoryLink.link}>
                  {ourStory?.wholeStoryText}
                </StyledLink>
              }
          />
        </ContentContainer>
      </If>
      <If condition={!isEmpty(counters)}>
        <CounterList>
          <For each="counter" of={counters} index="idx">
            <CounterList.Item
                key={idx}
                index={idx}
                count={counter.count}
                description={
                  <Typography type="h3">
                    {counter.description}
                  </Typography>
                }
                smallDescription
            />
          </For>
        </CounterList>
      </If>
      <If condition={!isEmpty(portfolioImages)}>
        <ContentContainer>
          <SectionPortfolio
              portfolioImages={portfolioImages}
              showCatalog={showCatalog}
              portfolioButton={portfolioButton}
          />
        </ContentContainer>
      </If>
      <Experience
          text={experience?.text}
          photo={experience?.photo}
          video={experience?.videoLink}
      />
      <SectionMaterials materials={materials} allMaterialsButton={allMaterialsButton} />
    </AppShell>
  )
}

export const data = graphql`
  query IndexPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      content
      featuredImage {
        node {
          altText
          srcSet
        }
      }
      seo {
        metaDesc
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          mediaItemUrl
        }
        twitterImage {
          mediaItemUrl
        }
      }
      template {
        ... on WpTemplate_DomovskaStranka {
          homePage {
            counters {
              count
              description
            }
            knowUsText
            portfolioButton {
              title
              url
            }
            showCatalog {
              title
              url
            }
            ourStory {
              description
              fieldGroupName
              title
              wholeStoryText
              wholeStoryLink {
                ... on WpPage {
                  link
                }
              }
              photo {
                srcSet
              }
            }
            banners {
              bannerImage {
                altText
                srcSet
              }
            }
            materials {
              material {
                ... on WpMaterial {
                  title
                  materials {
                    referenceImage {
                      altText
                      srcSet
                    }
                  }
                }
              }
            }
            portfolios {
              portfolio {
                ... on WpPortfolio {
                  title
                  featuredImage {
                    node {
                      altText
                      srcSet
                    }
                  }
                }
              }
            }
            experience {
              text
              videoLink {
                url
                title
              }
              photo {
                sourceUrl
                altText
              }
            }
            allMaterialsButton {
              title
              url
            }
          }
        }
      }
    }
  }
`

Index.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Index
